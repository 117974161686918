@import '../node_modules/normalize.css/normalize.css';

/**
 * Start app specific CSS
 */
 * {
    --primary-color: rgb(207,0,15);
    --primary-color-darker: rgb(150,40,27);
 }

html {
  box-sizing: border-box;
  font-size: 16px;
}

body {
  color: #3e4348;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 1.5rem;
  font-family: -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

.container {
  margin: 0.5rem auto 0 auto;
  max-width: 75rem;
  padding: 0 1rem;
  position: relative;
  width: 100%;
  overflow: hidden;
}

button, .btn {
  background-color: var(--primary-color, black);
  border: none;
  border-radius: 0.4rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  padding: .5rem 1rem;
  margin-bottom: .5rem;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.1rem;
  white-space: nowrap;
}
button:hover, button:focus, .btn:hover, .btn:focus {
  background-color: var(--primary-color-darker, black); /** #606c76; */
  color: #fff;
}
button[slot="confirm"], .btn[data-triggered="true"] {
    background-color: blue;
}
button[disabled] {
  opacity: 0.75;
  cursor: default;
}
button[disabled]:hover, button[disabled]:focus {
    opacity: 0.5;
}
/* Button that looks like link */
button.btn-plain, confirm-button.btn-plain {
    background-color: rgba(0, 0, 0, 0);
    color: var(--primary-color, black);
    border: none;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    text-transform: none;
    line-height: inherit;
    margin: 0;
    height: auto;
}
button.btn-plain:hover, button.btn-plain:focus, confirm-button.btn-plain:hover, confirm-button.btn-plain:focus {
    color: var(--primary-color-darker, black);
    text-decoration: underline;
    opacity: inherit;
    background-color: inherit;
}
/** Slightly smaller button */
button.btn-sm, confirm-button.btn-sm {
    font-size: 0.8rem;
    padding: .25rem .75rem;
    margin: 0;
}

button[slot="rollbuttons"] {
    margin-right: 1rem;
}

fieldset {
    border: 1px solid rgb(221, 221, 221);
    margin: 0 0 1rem 0;
    padding: 0.5rem;
}
legend {
    padding: 0 0.5rem;
}
fieldset ul {
    margin: 0 0.5rem;
}
fieldset li:last-of-type {
    margin-bottom: 0.5rem;
}

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='text'],
input[type='url'],
textarea,
select {
  background-color: transparent;
  border: 0.1rem solid #d1d1d1;
  border-radius: 0.4rem;
  box-shadow: none;
  width: 100%;
}
textarea {
    min-height: 7.5rem;
}
textarea.large {
/*     min-height: 21rem; */
}
input[type=file] {
    margin-bottom: 1.25rem;
}

label {
    font-weight: 500;
    display: block;
}

input[type='checkbox'],
input[type='radio'] {
  display: inline;
}

a {
    color: var(--primary-color, black);
    text-decoration: none;
}
a:hover {
  color: var(--primary-color-darker, black);
  text-decoration: underline;
}

dl,
ol,
ul {
    margin: 0;
    padding: 0;
}

dl {
  list-style: none;
}

ul {
  list-style: dot inside;
}
form ul  {
    list-style: none;
}
ul ul, ul ol, ol ul, ol ol {
    margin-left: 1rem;
}

ol {
  list-style: decimal inside;
}

dt,
dd,
li {
    margin: 0;
    padding: 0;
}
li:last-of-type {
    margin-bottom: 1.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}
p + p {
    margin-top: 1rem;
}
p + ul {
    margin-top: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

h1 {
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 1rem;
    margin-top: .5rem;
}

h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin: 0rem 0 1.25rem 0;
}

h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin: 0 0 1.25rem 0;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: .25rem 0 1.25rem 0;
}

table {
    border-collapse: collapse;
    text-align: left;
    border: none;
}
th, td {
    padding: .25rem;
}
table.table-editable {
    width: 100%;
    margin-bottom: 1.5rem;
}
table.table-editable tbody tr {
    border-bottom: 1px solid var(--primary-color, black);
    margin-bottom: .25rem;
}

.mg-top {
    margin-top: 1rem;
}


/*
    Page header/name and menu toggle
*/
.page-header {
    position: relative;
    display: flex;
    align-items: center;
}
h1 {
    flex: 4 1 auto;
}
.menu-open {
    flex: 1 4 auto;
    text-align: right;
}

/*
    Alerts and dialogs
*/
/* Close button in modal */
modal-mib button.close {
    float: right;
    margin: 1rem 0 0 1rem;
}
/* Close button in modal in form so it sits next to submit button. */
modal-mib form button.close {
    float: none;
    margin: 0 0 0 2rem;
}

/* Alerts in bottom corner of screen */
.alertContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 125;
}
.alert {
    z-index: 125;
    font-weight: bold;
    color: var(--primary-color, black);
    font-size: 0.75rem;
    line-height: 1rem;
    padding: .25rem 1rem;
    min-width: initial;
    margin: 1rem 1rem 1rem auto;
    border: 2px solid var(--primary-color, black);
    border-radius: 4px;
    background-color: #fefefe;
    box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.5);
}

.alert-delete {
    padding: 0;
    position: relative;
}
.alert-delete span {
    display: inline-block;
    padding: 0.75rem 1.5rem 1rem 1.5rem;
}
/** Delete undo timeout progress. */
.alert-delete span.delete-timeout {
    padding: 0;
    height: .5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--primary-color, black);
}
/** Only change this timing if the delete undo time changes. They need to match. */
.alert-delete span.delete-timeout.transition {
    transition: width linear 8s;
}
.alert-delete span.delete-timeout.timeout {
    width: 0%;
}

@media (min-width: 50.0rem) {
    .alertContainer {
        right: 2rem;
        left: auto;
    }
    .alert {
        margin: 2rem auto;
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 0.75rem 1.5rem;
        min-width: initial;
    }
}


/* Fields at top of page */
.field field-editable {
    min-width: 7rem;
}
.field field-editable.small {
    min-width: 3rem;
}

field-editable.smallinline {
    display: inline-block;
    min-width: 3rem;
    width: auto;
}
field-editable + strong + field-editable {
    text-align: right;
}

field-editable.smallarea {
    min-width: 100%;
    margin-bottom: 1.5rem;
    min-height: 4.5rem;
}
field-editable.largearea {
    min-width: 100%;
    margin-bottom: 1.5rem;
    min-height: 7.5rem;
}
.hidden {
    display: none;
}
*[hidden] {
    display: none !important;
}

dl.field {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;
    flex-wrap: wrap;
}
dl.field div {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: .5rem 1rem 1rem 0;
}
dl.field div.with_icon {
    margin-top: 0;
}
dl.field dt {
    display: inline-block;
    font-weight: 500;
    flex: 1 1 auto;
    text-align: left;
}
dl.field dd {
    display: inline-block;
    margin: 0 0 0 2rem;
    flex: 2 1 auto;
}

dl.field dd:first-of-type {
    margin-right: 1rem;
}
dl.field dd:last-of-type {
    margin-right: 0rem;
}

[data-name=armor_class] {
    background-image: url(../images/shield.svg);
    background-repeat: no-repeat;
    height: 3rem;
    width: 2rem;
    text-align: center;
    background-position: center;
    line-height: 2.5rem;
}
[data-name=hp_max] {
    background-image: url(../images/heart.svg);
    background-repeat: no-repeat;
    height: 3rem;
    width: 4rem;
    color: rgb(255, 255, 255);
    text-align: center;
    background-position: center;
    line-height: 2.5rem;
}
[data-name=hp_cur] {
    background-image: url(../images/heart_broken.svg);
    background-repeat: no-repeat;
    height: 3rem;
    width: 4rem;
    color: var(--primary-color, black);
    text-align: center;
    background-position: center;
    line-height: 2.5rem;
}

input[type='number'].small {
    display: inline-block;
    width: 3rem;
}

/* Larger than phone screen */
@media (min-width: 50.0rem), print {
    dl.field {
        flex-direction: row;
        align-items: center;
    }
    dl.field div  {
        margin-right: 2rem;
    }

    .grid {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .grid .fullwidth {
        grid-column: span 3;
    }
    .grid .grid-span-col-2 {
        grid-column: span 2;
    }
}


/*
    Areas for attributes and skills
*/
.pc-attributes, .pc-skills {
    /* list-style-type: none; */
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0 0 1.5rem 0;
    padding: 0;
}

/*
    Footer
*/
footer {
    margin: 1rem 0;
    padding: .5rem;
    border-top: 1px solid rgb(96, 108, 118);
}

@media (min-width: 50.0rem) {
    footer {
        display: flex;
        align-items: baseline;
    }
    footer h4 {
        flex: 1 1 50%;
    }
    footer ul {
        flex: 1 1 50%;
    }
}

/**
 * Main tabs
 */
ul[role=tablist] {
    border-bottom: 1px solid var(--primary-color, black);
    margin-bottom: 1rem;
}
ul[role=tablist] > li {
    display: inline-block;
    margin: 0 2rem 0 0;
}
ul[role=tablist] a {
    display: inline-block;
    border-radius: .4rem .4rem 0 0;
    border: 1px solid var(--primary-color, black);
    border-width: 1px 1px 0 1px;
    padding: .5rem .8rem;
    font-size: .9rem;
}
ul[role=tablist] a[aria-selected=true] {
    background-color: var(--primary-color, black);
    color: white;
}


@media print {
   body {
      color: #000;
      background: #fff;
      font-size: 10px;
   }
   .dialog, footer {
       display: none;
   }
   h1 {
       font-size: 1.25rem;
       line-height: 1.5rem;
       margin: .5rem 0 1rem 0;
   }
   h2, h3 {
       font-size: 1rem;
        line-height: 1.5rem;
        margin: 0 0 .5rem;
   }
   dl.field div {
       margin: 0 0 0.5rem 0;
   }
   .pc-attributes, .pc-skills {
       font-size: inherit;
       line-height: inherit;
   }
   field-editable.smallarea, field-editable.largearea {
       margin-bottom: .5rem;
       min-height: 0;
       display: block; /* somehow this makes a big difference with page breaks */
   }
}
